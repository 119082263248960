<template>
  <div class="mx-3 my-3">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div v-if="!isLoading">
      <div class="row">
        <!-- datepicker -->
        <div
          class="date col-12 col-md-6 d-flex flex-wrap justify-content-start"
        >
          <div class="m-1 position-relative date-picker">
            <date-picker
              v-model="dateFrom"
              mode="date"
              :masks="masks"
              is-required
            >
              <template v-slot="{ inputValue, togglePopover }">
                <input
                  :value="inputValue"
                  class="form-control-history"
                  readonly
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
            <img
              class="date-icon"
              src="@/assets/Maintenancing/history-date.svg"
            />
          </div>
          <div class="to my-auto ">{{ $t("maintenance_report.to") }}</div>
          <div class="m-1 position-relative date-picker">
            <date-picker
              v-model="dateTo"
              :min-date="dateFrom"
              :masks="masks"
              is-required
            >
              <template v-slot="{ inputValue, togglePopover }">
                <input
                  :value="inputValue"
                  class="form-control-history"
                  readonly
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
            <img
              class="date-icon"
              src="@/assets/Maintenancing/history-date.svg"
            />
          </div>
        </div>

        <!-- search & activity -->
        <div class="filterDiv col-12 col-md-6 d-flex justify-content-end my-2">
          <button
            @click="downloadReport"
            class="add-report mx-2 flex-shrink-0"
            type="button"
            :disabled="isDownloading"
          >
            <img src="@/assets/Maintenancing/download-all.svg" />
          </button>
          <div class="group-search">
            <span class="filter-box">
              <img src="@/assets/search-white.svg" width="17px" />
              <input
                type="text"
                class="filter-box"
                style="
                  height: 35px;
                  width: 80%;
                  border-radius: 10px;
                "
                :placeholder="$t('home.search')"
                v-debounce:700ms="getHistoryData"
                v-model="searchText"
              />
            </span>
          </div>
          <b-form-select
            v-model="selectedActivty"
            :options="activityOptions"
            class="activity"
          ></b-form-select>
        </div>
      </div>
    </div>
    <MaintenanceHistoryTable :tableData="tableData" />
    <div class="d-flex justify-content-center mt-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </div>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import vueClickOutside from "vue-click-outside";
import MaintenanceHistoryTable from "@/components/Table/Maintenance/MaintenanceHistoryTable.vue";
import baseUrl from "@/util/backend";
import { authHeader } from "@/store/actions";
import axios from "axios";
import Loading from "vue-loading-overlay";


export default {
  directives: {
    vueClickOutside,
  },
  components: {
    MaintenanceHistoryTable,
    DatePicker,
    Loading
  },
  data() {
    return {
      isDownloading: false,
      isLoading: false,
      tableData: [],
      currentPage: 1,
      perPage: 40,
      totalRows: 0,
      searchText: "",
      masks: {
        input: "DD-MM-YYYY",
      },
      dateFrom: undefined,
      dateTo: undefined,
      selectedActivty: null,
      activityOptions: [
        { value: null, text: this.$t("maintenance_report.all-activity") },
        {
          value: "status_change",
          text: this.$t("maintenance_report.change-status"),
        },
        {
          value: "priority_change",
          text: this.$t("maintenance_report.change-urgency"),
        },
        {
          value: "technician_change",
          text: this.$t("maintenance_report.assigning"),
        },
        {
          value: "create",
          text: this.$t("maintenance_report.create-maintain"),
        },
        {
          value: "detail_change",
          text: this.$t("maintenance_report.change-detail"),
        },
        // { value: "reset", text: this.$t("maintenance_report.reset") },
      ],
    };
  },
  watch: {
    // searchText(value) {
    //   console.log(value);
    // },
    dateFrom(value) {
      this.dateTo = this.dateFrom;
      moment(this.dateFrom)
        .startOf("d")
        .toISOString();
      this.dateFrom = moment(value)
        .startOf("d")
        .toISOString();
      // console.log(this.dateFrom);
      this.getHistoryData();
    },
    dateTo(value) {
      this.dateTo = moment(value)
        .endOf("d")
        .toISOString();
      // console.log(this.dateTo);
      this.getHistoryData();
    },
    selectedActivty(value) {
      this.selectedActivty = value;
      this.getHistoryData();
    },
    currentPage() {
      this.getHistoryData();
    },
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.getHistoryData();
  },
  methods: {
    async downloadReport() {
      try {
        this.isDownloading = true;
        const res = await axios.get(`${baseUrl()}maintain/log/excel`, {
          params: {
            id_company: this.id_company,
            action_type: this.selectedActivty,
            search: this.searchText,
            start_date:
              this.dateFrom == null
                ? moment()
                    .startOf("year")
                    .subtract(1, "year")
                    .toDate()
                : this.dateFrom,
            end_date: this.dateTo == null ? new Date() : this.dateTo,
          },
          ...authHeader(),
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "history.xlsx");
        document.body.appendChild(link);
        link.click();
        this.isDownloading = false;
      } catch (err) {
        this.isDownloading = false;
        console.log(err )
        alert('ไม่สามารถดาวน์โหลดได้')
      }
      
    },
    async getHistoryData() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl()}maintain/log`, {
          params: {
            id_company: this.id_company,
            page: this.currentPage,
            limit: this.perPage,
            action_type: this.selectedActivty,
            search: this.searchText,
            start_date:
              this.dateFrom == null
                ? moment()
                    .startOf("year")
                    .subtract(1, "year")
                    .toDate()
                : this.dateFrom,
            end_date: this.dateTo == null ? new Date() : this.dateTo,
          },
          ...authHeader(),
        });
        this.totalRows = res.data.message.count;
        // console.log(res.data);
        this.tableData = [];
        res.data.message.rows.forEach((item, index) => {
          let dateText = moment(item.request_at).format("DD/MM/YYYY-HH:mm");
          this.tableData.push({
            index: index + 1 + (this.currentPage - 1) * this.perPage,
            request_at: dateText,
            request_user_name: item.request_user_name,
            action_type: item.action_type,
            old_data: item.old_data,
            new_data: item.new_data,
            id_asset: item.id_fixed_asset,
            name_asset: item.asset_name,
            maintain_ref: item.maintain_ref,
          });
        });
        this.isLoading = false;
        // console.log(this.tableData);
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error)
        }
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  margin: 5px -40px !important;
}
.form-control-history {
  color: #017afd;
  max-width: 65%;
  border: #017afd 2px solid !important;
  border-radius: 8px;
  margin: 0;
  background-color: #ffffff !important; 
  // padding: 0;
}
.date-icon {
  position: absolute;
  right: 21%;
  top: 25%;
}
.to {
  color: #017afd;
  font-size: 13px;
}
.activity {
  background-color: #ffffff;
  border: 2px solid #017afd;
  border-radius: 8px;
  color: #017afd;
  min-width: 30%;
  padding: 7px 13px;
  font-size: 13px;
  background-image: url("../../assets/Maintenancing/maintenance-drop-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% 50%;
  font-weight: 400;
  margin-left: 10px;

  :active {
    border: 2px solid #017afd !important;
    box-shadow: none !important;
  }
}
.filter-box {
  border-radius: 8px;
  display: flex;
  padding: 3px 12px;
  background-color: #ededed;
  max-width: 150px;
  font-size: 13px;
}
input {
  margin: auto !important;
}
// img {
//   width: 15px;
// }
.add-report {
  border-radius: 10px 10px 10px 10px !important;
  background: #007afe !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
  }
}
@media only screen and (max-width: 387px) {
  .date {
    display: block !important;
  }
  .date-icon {
    right: 23%;
    top: 30%;
  }
  .form-control-history {
    max-width: 60%;
  }

  .filterDiv {
    justify-content: center !important;
  }
}

@media only screen and (min-width: 768px) {
  .to {
    font-size: 16px;
  }
  .activity {
    font-size: 16px;
  }
  .filter-box {
    font-size: 16px;
  }
}
</style>
